import { Tracking as SharedTracking } from "../shared/Tracking";

export const Tracking = () => (
  <SharedTracking
    didomiApiKey={{
      noticeId: "da13fe0c-87b0-43d4-9f4c-267705969e08",
      target: "Q7jWVFpX",
    }}
    gtmApiKey="GTM-5768KTH2"
  />
);
